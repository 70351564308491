<template>
  <div class="px-0 lg:px-20">
    <!--Search Bar -->
    <div
      class="
        p-4
        my-5
        flex flex-row
        items-center
        justify-center
        xl:justify-between
        rounded-lg
        shadow
        bg-white
      "
    >
      <h1 class="text-2xl font-semibold text-gray-700 flex-1 font-EffraR">
        <i class="fas fa-file-prescription"></i> Ordonnances de:
        {{ patient.gender === "MALE" ? "Mr" : "Mme" }}.{{
          patient.lastName + " " + patient.firstName
        }}
      </h1>
      <div
        class="
          flex flex-col
          xl:flex-row
          items-center
          gap-y-3
          xl:gap-y-0
          gap-x-0
          xl:gap-x-2
          mr-5
        "
      ></div>
    </div>

    <!--Loader -->
    <div class="h-full w-full" v-if="loader">
      <div
        class="
          bg-white bg-opacity-60
          px-8
          py-2
          h-screen
          w-full
          flex
          justify-center
          items-center
        "
      >
        <loader></loader>
      </div>
    </div>

    <!-- Data -->
    <!--name cols -->
    <div
      v-if="!loader"
      class="
        bg-white
        border-0 border-b border-solid border-gray-300
        py-3
        px-3
        grid
        gap-1
        grid-cols-4
        md:grid-cols-5
        shadow
      "
    >
      <span class="font-bold text-base mb-2 xl:mb-0 font-EffraR">Type</span>
      <span
        class="font-bold text-base mb-2 xl:mb-0 font-EffraR hidden md:inline"
        >Note</span
      >
      <span class="font-bold text-base mb-2 xl:mb-0 font-EffraR">Date</span>
      <span class="font-bold text-base mb-2 xl:mb-0 font-EffraR"
        >Par Docteur</span
      >
      <span class="font-bold text-base mb-2 xl:mb-0 font-EffraR"></span>
    </div>
    <!--Data patients -->
    <div
      class="
        bg-white
        border-0 border-b border-solid border-gray-300
        py-3
        px-3
        grid
        gap-1
        grid-cols-4
        md:grid-cols-5
        shadow
      "
      v-for="(sdr, i) in ordonnances"
      :key="i"
      :class="i === 0 ? 'rounded-t-lg' : ''"
    >
      <span class="font-bold text-base mb-2 font-EffraR">{{
        sdr.type ? sdr.type : "-"
      }}</span>
      <span class="font-EffraL text-base hidden md:inline">{{
        sdr.note ? sdr.note : "-"
      }}</span>
      <div>
        <span class="font-EffraL text-base hidden md:inline">{{
          moment()(sdr.created_at).format("DD MMM YYYY")
        }}</span>
        <span class="font-EffraL text-base md:hidden">{{
          moment()(sdr.created_at).format("DD/MM")
        }}</span>
      </div>
      <span class="font-EffraL text-base">{{
        (sdr.doctorId.title ? sdr.doctorId.title + "." : "Dr.") +
        sdr.doctorId.lastName +
        " " +
        sdr.doctorId.firstName
      }}</span>
      <div class="flex justify-end">
        <button
          @click="
            $router.push({
              name: 'pharmacyPrescriptionByID',
              params: { id: sdr._id },
            })
          "
          class="
            hidden
            md:inline
            py-2
            px-5
            w-auto
            font-EffraR
            bg-transparent
            hover:bg-blackdok
            text-blackdok
            hover:text-white
            border-2 border-solid border-blackdok
            rounded-cu
            cursor-pointer
          "
        >
          Ordonnance
        </button>
        <button
          @click="
            $router.push({
              name: 'pharmacyPrescriptionByID',
              params: { id: sdr._id },
            })
          "
          class="
            md:hidden
            py-2
            px-3
            w-auto
            font-EffraR
            bg-transparent
            hover:bg-blackdok
            text-blackdok
            hover:text-white
            border-2 border-solid border-blackdok
            rounded-cu
            cursor-pointer
          "
        >
          <i class="fas fa-file-prescription"></i>
        </button>
      </div>
    </div>
    <!-- Null Data patients -->
    <div
      v-if="ordonnances.length == 0"
      class="bg-white 0 px-8 py- w-full flex justify-center items-center"
    >
      <img alt="vide" src="/svg/null_data.svg" class="max-h-300 xl:max-h-500" />
    </div>
    <!-- pagination -->
    <div class="mt-4 py-5 flex justify-center items-center" v-if="pages > 1">
      <div class="flex flex-row">
        <div>
          <a
            href="javascript:void(0)"
            @click="
              page--;
              loadOrdonnances();
            "
            :class="page == 1 ? 'disable ' : ''"
            class="
              px-4
              py-4
              mx-5
              border-0
              shadow-sm
              text-nblue
              rounded-full
              cursor-pointer
              hover:shadow-lg
              hover:text-gray-100
              hover:bg-nblue
            "
            ><i class="fa fa-angle-left" aria-hidden="true"></i
          ></a>
        </div>
        <div v-for="item in pages" :key="item">
          <a
            href="javascript:void(0)"
            @click="
              page = item;
              loadOrdonnances();
            "
            :class="
              page == item
                ? 'shadow-lg text-gray-100 bg-nblue disable'
                : 'text-nblue'
            "
            class="
              px-4
              py-4
              mx-1
              border-0
              shadow-sm
              rounded-full
              cursor-pointer
              hover:shadow-lg
              hover:text-gray-100
              hover:bg-nblue
            "
            >{{ item }}</a
          >
        </div>
        <div>
          <a
            href="javascript:void(0)"
            @click="
              page++;
              loadOrdonnances();
            "
            :class="page == pages || pages == 0 ? 'disable ' : ''"
            class="
              px-4
              py-4
              mx-5
              border-0
              shadow-sm
              text-nblue
              rounded-full
              cursor-pointer
              hover:shadow-lg
              hover:text-gray-100
              hover:bg-nblue
            "
            ><i class="fa fa-angle-right" aria-hidden="true"></i
          ></a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import loader from "@/views/global-components/loader";
export default {
  components: { loader },
  data() {
    return {
      page: 1,
      pages: 1,
      ordonnances: [],
      patient: {},
      loader: true,
    };
  },
  async mounted() {
    await this.loadOrdonnances();
  },
  methods: {
    moment() {
      return moment;
    },
    async loadOrdonnances() {
      this.loader = true;
      const { ok, data, patient, pages } = await this.$store.dispatch(
        "laboratory/GET_PATIENT_ORDANNANCES",
        { id: this.$route.params.id, page: this.page }
      );
      if (ok) {
        this.patient = patient;
        this.ordonnances = data;
        this.pages = pages;
      }
      this.loader = false;
    },
  },
};
</script>
<style scoped>
.disable {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}
</style>
